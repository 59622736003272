.guarantee {
  @media (max-width: $breakpoint992) {
    height: 60rem;
  }
  &__wrap {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after {
      display: block;
      content: "";
      position: absolute;
      background-color: var(--color-text-hover);
      width: 1px;
      height: 90%;
      right: 15px;
      top: 5%;
    }
    @media (max-width: $breakpoint768) {
      display: flex;
      flex-direction: column;
      &::after {
        height: 43rem;
        top: -5rem;
      }
    }
  }

  &__info {
    width: 34%;
    @media (max-width: $breakpoint992) {
      width: 40%;
    }
    @media (max-width: $breakpoint768) {
      width: 100%;
      order: 2;
    }
    .title {
      max-width: 50%;
      margin-bottom: 6.8rem;
      @media (max-width: $breakpoint768) {
        max-width: 100%;
      }
    }
  }
  &__img {
    width: 55%;
    height: 72%;
    background-image: url("../img/img_4.png");
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: $breakpoint768) {
      width: 100%;
      height: 33rem;
      order: 1;
      margin-bottom: 3rem;
    }
  }
}
