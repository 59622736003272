.hero {
  @media (max-width: $breakpoint1200) {
    height: 100vh;
  }
  @media (max-width: $breakpoint768) {
    padding-top: 9rem;
    padding-bottom: 5rem;
  }

  .main-logo {
    text-align: center;
    h1 {
      font-size: 3.9rem;
    }
    @media (max-width: $breakpoint992) {
      svg {
        max-height: 4rem;
      }
      h1 {
        font-size: 3rem;
      }
    }
    @media (max-width: $breakpoint768) {
      h1 {
        font-size: 2.6rem;
      }
    }
    @media (max-width: $breakpoint540) {
      max-width: 100%;
      svg {
        max-height: 3rem;
      }
      h1 {
        font-size: 2.2rem;
      }
    }
  }

  .slogan {
    font-family: var(--fonts-header);
    margin-bottom: 0;
    margin-top: 1rem;
    @media (max-width: $breakpoint540) {
      max-width: 100%;
    }
  }
}
