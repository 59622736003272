.partner {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 6.2rem;
  @media (max-width: $breakpoint768) {
    // padding-top: 2rem;
    // padding-bottom: 2rem;
    .flex {
      flex-direction: column;
    }
  }
  &__logo {
    width: 100%;
    display: flex;
    justify-content: center;
    // margin-bottom: 6.2rem;
    padding-top: 2rem;
    svg {
      max-height: 2.5rem;
      // max-width:
    }
    @media (max-width: $breakpoint768) {
      display: none;
    }
  }
  &__title {
    position: absolute;
    background-color: var(--color-decor-primary);
    font-size: 2rem;
    padding: 1.9rem 13rem;
    transform: rotate(-90deg);
    top: 44%;
    right: -46%;
    @media (max-width: $breakpoint992) {
      right: -50%;
    }
    @media (max-width: $breakpoint768) {
      // transform: rotate(0deg);
      padding: 1.5rem 7rem;
      right: -20%;
      top: 42%;
    }
  }

  &__img {
    width: 36%;
    height: 57rem;
    position: relative;
    background-image: url("../img/img_1.png");
    @media (max-width: $breakpoint1200) {
      height: 100%;
      background-size: cover;
      width: 43%;
    }
    @media (max-width: $breakpoint992) {
      width: 50%;
    }
    @media (max-width: $breakpoint768) {
      width: 100%;
      height: 30rem;
      content: "";
      margin-bottom: 5rem;
    }
  }

  &__info {
    width: 36%;
    margin-right: 5.5%;
    .title {
      margin-bottom: 6.4rem;
    }
    @media (max-width: $breakpoint1200) {
      width: 42%;
      margin-right: 0;
      .title {
        margin-bottom: 5.4rem;
      }
    }
    @media (max-width: $breakpoint992) {
      width: 40%;
    }
    @media (max-width: $breakpoint768) {
      width: 100%;
      .title {
        margin-bottom: 3rem;
        text-align: center;
      }
      form {
        display: none;
      }
    }
  }
}
