/* ============================= [   Абсолютное позиционирование   ] =============================== */
@mixin abs-position ($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin abs-v-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin abs-h-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

