.item {
  content: "";
  //height: 200px;
  width: 100%;
  padding: 1.5rem;
  border: 1px solid var(--color-text-placeholder);
  overflow: hidden;
  flex-basis: 1;
  color: var(--color-text-search);
  font-size: 1.3rem;

  &__photo {
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // gap: 2rem;
    margin-bottom: 2rem;

    &__main {
      height: 20rem;
      margin-bottom: 1rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: $breakpoint1200) {
        height: 15rem;
      }

      img {
        max-height: 100%;
        object-fit: contain;
        object-position: 50%;
        cursor: pointer;
      }
    }

    // .photo__img {
    //   display: block;
    //   width: auto;
    //   flex-basis: 46%;
    //   flex-grow: 0;
    //   object-fit: cover;
    //   height: 10rem;
    //   &:first-of-type {
    //     height: 21rem;
    //     flex-basis: 100%;
    //     flex-grow: 1;
    //   }

    //   @media (max-width: $breakpoint1200) {
    //     flex-basis: 43%;
    //     width: 43%;
    //     &:first-of-type {
    //       height: 15rem;
    //     }
    //   }
    // }
  }

  .title {
    font-size: 1.3rem;
    margin-bottom: 2rem !important;
  }
  &__features {
    &_item {
      display: block;
      // margin-bottom: 0.5rem;
      line-height: 2rem;
    }
  }

  .swiper {
    width: 100%;
    // height: 300px;
    margin-left: auto;
    margin-right: auto;
    &-slide {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .swiper-thumb {
    height: 10rem;
  }
}
