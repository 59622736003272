.quality {
  // height: 100%;
  width: 100%;
  position: relative;
  @media (max-width: $breakpoint768) {
    display: flex;
    flex-direction: column;
  }
  &__bg {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    background-image: url(../img/img_2.png);
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: $breakpoint992) {
      left: 55%;
      width: 45%;
    }
    @media (max-width: $breakpoint768) {
      width: 100%;
      position: static;
      content: "";
      height: 30rem;
      display: none;
    }
  }
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: $breakpoint768) {
      position: relative;
      flex-direction: column;
      padding-top: 50rem;
      background-image: url(../img/img_2.png);
      background-size: 100% 45rem;
      background-repeat: no-repeat;
    }
  }
  .original {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-left: 8rem;
    padding-right: 10rem;
    @media (max-width: $breakpoint1200) {
      padding-left: 5rem;
      padding-right: 5rem;

      p:last-of-type {
        margin-bottom: 0;
      }
    }
    @media (max-width: $breakpoint992) {
      width: 55%;
    }

    @media (max-width: $breakpoint768) {
      width: 100%;
      padding: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0rem;
      background-color: var(--color-decor-second);
      width: 2px;
      height: 100%;
      @media (max-width: $breakpoint768) {
        display: none;
      }
    }
    &__info {
      font-weight: 400;
      line-height: 2.5rem;
    }

    .title {
      margin-bottom: 7rem;
      @media (max-width: $breakpoint992) {
        margin-bottom: 5.4rem;
      }
      @media (max-width: $breakpoint768) {
        margin-bottom: 3.5rem;
      }
    }
  }

  .poduction {
    max-width: 36rem;
    padding: 8.5rem 5.3rem 2.3rem 7.6rem;
    position: absolute;
    top: 0;
    left: 50%;
    background-color: var(--color-decor-primary);
    @media (max-width: $breakpoint992) {
      left: 55%;
      padding: 8rem 0rem 2rem 5rem;
      max-width: 32rem;
    }
    @media (max-width: $breakpoint768) {
      left: 0;
    }
    .title {
      font-size: 2.7rem;
      line-height: 3.1rem;
      margin-bottom: 4.9rem;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -3rem;
        background-color: var(--color-text-primary);
        height: 1px;
        width: 20%;
      }
    }
  }
  &__info {
    span {
      display: block;
      margin-bottom: 1.8rem;
    }
  }
}
