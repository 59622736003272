@use "sass:math";

:root {
  /* ================ [   COLORS   ] ===================== */
  --color-text-primary: #ffffff;
  --color-text-placeholder: #808080;
  --color-text-hover: #f5a000;
  --color-text-search: #000000;

  --color-bg-primary: radial-gradient(
    43.11% 76.7% at 50% 50%,
    #333333 0%,
    #1a1a1a 100%
  );
  --color-bg-secondary: #f5f5f5;

  --color-decor-primary: #000a50;
  --color-decor-second: #f5a000;

  --color-btn: #000000;
  --color-input: #ffffff;

  --fonts-primary: "Roboto";
  --fonts-header: "Franklin-Gothic-Demi-Regular";
}

/* ================ [   Breackpoints and container   ] ===================== */
$containerWidth: 1200;
$containerPadding: 30;
$containerSidePadding: math.div($containerPadding, 2);
$breakpoint1450: 1449.98 + px;
$breakpoint1200: 1199.98 + px;
$breakpoint992: 991.98 + px;
$breakpoint768: 767.98 + px;
$breakpoint540: 539.98 + px;
$breakpoint480: 479.98 + px;

/* ============================ [   Fonts   ] ============================== */

// $fontFamily: "Lato";
$fontSize: 1.3rem;
$fontColor: #ffffff;
