.contacts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  @media (max-width: $breakpoint768) {
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
  }

  &__logo {
    content: "";
    width: 215px;
    height: 25px;
    display: block;
    position: relative;
    background-image: url("../img/logo.svg");
    background-size: cover;
    transform: rotate(-90deg);
    position: absolute;
    bottom: 20%;
    left: -6%;
    @media (max-width: $breakpoint992) {
      left: -10%;
      top: 20rem;
      bottom: auto;
      left: -12%;
    }

    @media (max-width: $breakpoint768) {
      position: absolute;
      height: 2rem;
      width: 18rem;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:after {
      position: absolute;
      left: 100%;
      top: 50%;
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      margin-left: 5px;
      background-color: var(--color-decor-second);
      @media (max-width: $breakpoint768) {
        width: 65%;
      }
    }
  }

  &__img {
    content: "";
    width: 39%;
    height: 100%;
    background-image: url(../img/img-5.png);
    background-repeat: no-repeat;
    border-right: 25px solid var(--color-decor-primary);
    margin-left: 10rem;
    @media (max-width: $breakpoint1200) {
      width: 42%;
    }
    @media (max-width: $breakpoint992) {
      margin-left: 5rem;
      width: 45%;
    }
    @media (max-width: $breakpoint768) {
      height: 30rem;
      width: 80%;
      background-size: cover;
      margin-right: 0;
      margin-left: auto;
      background-position: 50%;
      margin-bottom: 5rem;
    }
  }

  &__block {
    width: 35%;
    @media (max-width: $breakpoint1200) {
      width: 40%;
    }
    @media (max-width: $breakpoint768) {
      width: 100%;
    }
  }

  &__title,
  &__info {
    display: block;
    padding-left: 6rem;
    margin-bottom: 5rem;
    @media (max-width: $breakpoint992) {
      padding-left: 0;
    }
    @media (max-width: $breakpoint768) {
      padding-left: 2.5rem;
    }
  }
  &__info {
    margin-bottom: 3rem;
  }
}
