.form {
  display: flex;
  flex-direction: column;

  &__group {
    position: relative;
  }
  &__group:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__input,
  &__textarea {
    box-sizing: border-box;
    font-size: 1.3rem;
    font-family: inherit;
    padding: 1.1rem 3rem;
    // border-radius: 3px;
    background-color: var(--color-input);
    color: var(--color-text-placeholder);
    // border: 0;
    border: 1px solid var(--color-text-placeholder);
    display: block;
    width: 100%;
    transition: all 0.3s;
    outline: none;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba(var(--color-btn), 0.1);
      border-bottom: 2px solid var(--color-decor-second);
      &:invalid {
        border-bottom: 2px solid var(--color-decor-second);
      }
    }
    &::placeholder {
      color: var(--color-text-placeholder);
    }
  }

  .error {
    border-bottom: 2px solid red;
  }
  .has-error input {
    border-bottom: 2px solid red;
  }

  &__textarea {
    outline: none;
    height: 9rem;
    resize: none;
  }

  &__label {
    display: none;
    // margin-left: 2rem;
    // margin-top: 0.7rem;
    position: absolute;
    // display: block;
    transition: all 0.3s;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }
}
