// Container
.container {
  max-width: ($containerWidth + $containerPadding) + px;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 $containerSidePadding + px;
  &::before,
  &::after {
    display: none;
  }

  @media (max-width: $breakpoint1450) {
    max-width: 1140px;
  }
  @media (max-width: $breakpoint1200) {
    max-width: 960px;
  }

  @media (max-width: $breakpoint992) {
    // max-width: 970px;
    max-width: 769px;
  }

  @media (max-width: $breakpoint768) {
    max-width: 540px;
  }

  @media (max-width: $breakpoint540) {
    min-width: 100%;
    // max-width: 100%;
  }
}

.page {
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  font-size: 62.5%; // 1rem = 10px (10 / 16 = 62.5%)
  background-color: #1a1a1a;
  // scroll-behavior: smooth;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #1a1a1a;

  font-family: var(--fonts-primary);
  font-size: $fontSize;
  color: var(--color-text-primary);
  @media (max-width: $breakpoint768) {
    padding-top: 7rem;
  }
}
// .no-scroll {
//   overflow: hidden;
// }

* {
  box-sizing: border-box;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn {
  background-color: var(--color-btn);
  border: 1px solid var(--color-btn);
  box-shadow: none;
  color: var(--color-text-primary);
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 0 0 0 auto;
  padding: 1.4rem;
  min-width: 22rem;
  font-weight: bold;
  font-size: 1.3rem;
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80rem;
  background: radial-gradient(
    43.11% 76.7% at 50% 50%,
    #333333 0%,
    #1a1a1a 100%
  );
  background-repeat: no-repeat;
  // &:first-child {
  //   // height: 100vh;
  // }
  @media (max-width: $breakpoint992) {
    height: 70rem;
  }
  @media (max-width: $breakpoint768) {
    height: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.bold {
  font-weight: 700;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  text-transform: uppercase;
  font-size: 3.7rem;
  font-weight: 400;
  @media (max-width: $breakpoint992) {
    font-size: 2.8rem;
    margin-bottom: 5.4rem !important;
  }
  @media (max-width: $breakpoint768) {
    margin-bottom: 3.5rem !important;
  }
  // font-family: var(--fonts-header);
  &__decor {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 30%;
      height: 1px;
    }
  }
}

.logo {
  background-image: url("../img/logo.svg");
}

p {
  line-height: 1.8rem;
  margin-bottom: 4rem;
  @media (max-width: $breakpoint992) {
    line-height: 1.7rem;
    margin-bottom: 2.2rem;
  }
  @media (max-width: $breakpoint768) {
    margin-bottom: 1.9rem;
  }
  &:last-of-type {
    margin-bottom: 3rem;
    @media (max-width: $breakpoint992) {
      margin-bottom: 2rem;
    }
  }
}
