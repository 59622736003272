//====================   [ Header Style ]  ====================
.header {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: $breakpoint768) {
    background-color: #1a1a1a;
  }
  @media (max-width: $breakpoint540) {
    // max-width: auto;
  }

  &__bg {
    background-color: #1a1a1a;
    .menu__logo {
      opacity: 1 !important;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    position: relative;
    @media (max-width: $breakpoint768) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }

    &__logo {
      opacity: 0;
      svg {
        max-height: 2.5rem;
        height: 2.5rem;
      }

      @media (max-width: $breakpoint992) {
        transform: translate(0%, 0%);
        left: 0;
      }
      @media (max-width: $breakpoint768) {
        display: flex;
        opacity: 1;
        align-items: center;
        left: 0;
        width: auto;
        transform: translate(0%, 0%);
        svg {
          height: 1.5rem;
          width: auto;
        }
      }
    }

    &-nav {
      display: flex;
      justify-content: flex-end;
      gap: 3rem;
      font-size: 1.6rem;
      margin-bottom: 0;
      @media (max-width: $breakpoint540) {
        font-size: 1.5rem;
        li:not(:first-of-type) {
          display: none;
        }
      }
      &__link {
        position: relative;
        color: inherit;
        text-transform: uppercase;

        &:before {
          display: block;
          position: absolute;
          content: "";
          width: 0;
          height: 1px;
          bottom: 0;
          left: 0;
          transition: all 0.3s ease-in-out;
          background-color: var(--color-text-hover);
        }

        &:hover::before {
          width: 100%;
        }
      }

      &__sep {
        display: block;
        content: "";
        background-color: var(--color-text-primary);
        width: 1px;
        height: 16px;
      }
    }
  }
}
