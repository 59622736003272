.services {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: url("../img/img_3.png");
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 50%;
  @media (max-width: $breakpoint1200) {
    background-size: 120% 50%;
  }

  @media (max-width: $breakpoint768) {
    background-image: none !important;
    position: relative;
    padding-top: 17rem;
    background-color: var(--color-decor-primary);
    padding-bottom: 0;
    margin-bottom: -4rem;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      height: 17rem;
      width: 100%;
      background-image: url("../img/img_3.png");
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
    }
  }

  &__info {
    width: 47rem;
    background-color: var(--color-decor-primary);
    padding: 11rem 6.5rem 7.7rem 4.5rem;
    @media (max-width: $breakpoint1200) {
      padding: 8rem 5rem 7rem 4.5rem;
    }
    @media (max-width: $breakpoint768) {
      width: 100%;
      padding: 4rem 5rem 7rem 0rem;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
    .title {
      margin-bottom: 10.1rem !important;
      position: relative;
      font-weight: 400;
      &::after {
        display: block;
        position: absolute;
        content: "";
        background-color: var(--color-input);
        width: 7rem;
        height: 1px;
        left: 0;
        bottom: -6.5rem;
      }

      @media (max-width: $breakpoint768) {
        width: min-content;
      }
    }
  }
}
