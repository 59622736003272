.search {
  padding-top: 12rem;
  padding-bottom: 2rem;
  background-color: var(--color-text-primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;

  display: none;

  position: relative;
  top: 0px;
  left: 0;
  right: 0;

  @media (max-width: $breakpoint768) {
    padding-top: 9rem;
  }
  &-open {
    display: flex;
    top: 100%;
    transition: top 0.5s ease;
  }
  &__close {
    position: absolute;
    right: 3%;
    top: 2rem;
    display: flex;
    align-items: center;
    color: var(--color-text-placeholder);
    cursor: pointer;
    font-size: 1.4rem;

    svg {
      width: 2rem;
      height: 2rem;
      fill: #434342;
      margin-left: 2rem;
    }
    &:hover {
      color: var(--color-text-hover);
    }
    &:hover svg {
      fill: var(--color-text-hover);
    }
  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: aqua;
    width: 50%;

    @media (max-width: $breakpoint768) {
      width: 100%;
      max-width: 510px;
    }
    @media (max-width: $breakpoint768) {
      max-width: 80%;
    }
    &__wrap {
      position: relative;
      display: flex;
      justify-content: center;
      overflow: unset;
      // padding-bottom: 4rem;
    }
  }

  &__input {
    outline: none;

    width: 100%;
    border: 0;
    text-align: center;
    border-bottom: 1px solid var(--color-text-placeholder);
    padding: 0.8rem 3rem;
    color: var(--color-text-placeholder) !important;
    background-color: #fff !important;
  }

  &__decoration {
    bottom: 0;
    width: 10%;
    height: 2px;
    content: "";
    background-color: var(--color-decor-second);
    @include abs-v-center;
    opacity: 0;
    // transition: all 1s ease;
  }

  .search__input:focus + .search__decoration {
    opacity: 1;
    width: 100%;
    transition: all 1s ease;
  }
  .search__input:not(:focus) + .search__decoration {
    opacity: 0;
    width: 10%;
    transition: all 1s ease;
  }

  &__btn {
    position: absolute;
    bottom: 2px;
    right: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    svg {
      fill: #424242;
    }

    &:hover svg {
      fill: var(--color-text-hover);
    }
  }

  &__list {
    padding-top: 6rem;
    padding-bottom: 10rem;
    color: var(--color-text-placeholder);
    // width: 70%;
    overflow-y: scroll;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
    position: relative;
    &::before {
      display: none;
    }
    @media (max-width: $breakpoint540) {
      justify-content: center;
    }
    .item {
      max-width: 31.5%;
      max-height: 58rem;
      @media (max-width: $breakpoint1200) {
        width: 31.9%;
      }
      @media (max-width: $breakpoint992) {
        width: 48.5%;
        max-width: 50%;
      }
      @media (max-width: $breakpoint768) {
        width: 48%;
      }
      @media (max-width: $breakpoint540) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
