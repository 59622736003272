.control {
  @media (max-width: $breakpoint992) {
    height: 60rem;
  }
  @media (max-width: $breakpoint768) {
    .flex {
      flex-direction: column;
    }
    svg {
      display: none;
    }
  }
  &__graph {
    width: 50%;
    margin-left: 5%;
    overflow: hidden;
    @media (max-width: $breakpoint1200) {
      margin-left: 0;
      width: 58%;
    }
    @media (max-width: $breakpoint768) {
      width: 100%;
      height: 37rem;
      background-image: url("../img/control.svg");
      background-repeat: no-repeat;
      background-position: 50%;
      margin-bottom: 3rem;
    }
    svg {
      object-fit: cover;

      width: 100%;
      max-width: 100%;
    }
  }
  &__info {
    width: 37%;
    @media (max-width: $breakpoint768) {
      width: 100%;
    }
    .title {
      max-width: min-content;
      margin-bottom: 7rem;
      @media (max-width: $breakpoint768) {
        max-width: max-content;
      }

      &__decor:after {
        background-color: var(--color-decor-second);
        bottom: -4rem;

        @media (max-width: $breakpoint768) {
          bottom: -1rem;
          width: 20%;
        }
      }
    }
    p {
      line-height: 1.9rem;
    }
  }
}
