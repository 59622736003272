@keyframes moveInRight{
  0% {
    transform: translateX(-100px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }

}

@keyframes moveInLeft{
  0% {
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }
