/* ============================ [   Global SCSS imports   ] ============================== */
@import "./mixins";
@import "./vendor";
@import "./vars";
@import "./fonts";
@import "./settings";

@import "./keyframes/keyframes";

/* ============================= [   Components import   ] =============================== */
// @import "../components/accordion/accordion.scss";

/* ==================== [   // Project blocks HTML SCSS imports   ] ====================== */
@import "./blocks/header";
@import "./blocks/search";
@import "./blocks/item";
@import "./blocks/footer";
@import "./blocks/form";
@import "./blocks/hero";
@import "./blocks/quality";
@import "./blocks/contact";
@import "./blocks/partner";
@import "./blocks/control";
@import "./blocks/services";
@import "./blocks/guarantee";
